export const teamCopy = {
  Jordan: [
    {
      text: "In his constant pursuit for the perfect cocktail, Jordan Bushell built Libation Consulting Ltd, in 2009.  Travelling all around the globe to discover new flavors and techniques, he brings his knowledge, experience and passion when speaking at shows, crafting cocktails and educating on all things in the beverage world."
    },
    {
      text: "Since starting Libation, Bushell has traveled and mixed throughout the Americas, Europe and Asia, creating unique and prolific cocktails and educating for celebrities, industry veterans and anyone that shares a passion for libations. Pairing unique history and products with modern mixology, Bushell’s cocktails offer a diverse array of flavors for all palettes, while his education sessions are deep and engaging. "
    },
    {
      text:"Through Libations initiatives with partners, Bushell is able to share his knowledge of drink history, complexity, and education set with bartenders across the world. He prides himself on developing courses and programs that inspire bartenders and aficionados and lead to culture shifts and advocacy.  His work has encouraged consumers and professionals both in North America and around the world to further their quest for deep knowledge."
    },
    {
      text: "Bushell has lead efforts to establish a strong presence in bars across the world for partners and played an integral role in the renewed appreciation of some spirits.  As the Chief Consultant at Libation, he has been published in several books, designed high-end mixology tools, and appeared in countless television programs and print publications. He is also a regular presenter, activating countless events at key industry events like Tales of the Cocktail, Barra Mexico, Bar Convent Brooklyn and Bar Institute among others."
    },
    {
      text: "Bushell holds certifications in WSET wine and spirits certificate, a Pru D’homme beer specialization and countless spirit courses and seminars both at home and abroad. He also spent a decade honing his skills and gaining valuable experience at the forefront of the service industry, running distinguished cocktail programs to share the joy of the cocktail experience globally."
    }

  ],
  Trevor: [
    {text: "Trevor Mayoh, a hospitality professional with over twelve years experience and a recognised specialist in diversity, inclusion, and violence prevention. Through his work with government and corporate partners Mayoh has deconstructing gender norms and creating innovative violence prevention programming. Beyond Hospitality Mayoh has expertise working with technology, extractive, and post-secondary organizations internally and externally to help foster more inclusive, safe, and successful environments. Mayoh is a regular commentator on Newstalk 1010 programs and has been featured in numerous print, radio, and television media around the world."},
  ],
  Isabelle: [
    {
      text: "Isabelle Speerin is an award-winning marketing communications executive with over fifteen years experience directing strategic marketing campaigns in the private, public, and not-for-profit sectors. Over her career, Isabelle has counseled and executed numerous campaigns for clients including the Ontario Ministry of the Environment, Gowlings LLP (Canada’s largest law firm), and the Canadian Songwriters Hall of Fame, where she produced two Gemini-award winning Gala events broadcast nationally on CBC Television and CBC Radio-Canada."
    },
    {
      text: "More recently, Isabelle was responsible for telling the stories of Ontario’s most talented start-ups and entrepreneurs to the world. She wrote close to a hundred success stories and produced fifty videos showcasing Ontario’s most talented entrepreneurs, academics, and their groundbreaking technology collaborations during her five-year tenure at Ontario Centres of Excellence (OCE)."
    },
    {
      text: "Now a freelance writer, marketer, and video producer, Isabelle advises clients including Libation Ltd., luxury goods conglomerate LVMH Moët Hennessy, the Canadian Music Publishers Association (CMPA), and a number of technology start-ups. Isabelle graduated with a bachelor’s degree in Communications from Griffith University in Queensland, Australia, and was recognized for her academic achievements with two awards for academic excellence."
    },
    {
      text: "Now a freelance writer, marketer, and video producer, Isabelle advises clients including Libation Ltd., luxury goods conglomerate LVMH Moët Hennessy, the Canadian Music Publishers Association (CMPA), and a number of technology start-ups. Isabelle graduated with a bachelor’s degree in Communications from Griffith University in Queensland, Australia, and was recognized for her academic achievements with two awards for academic excellence."
    },
  ],
  Aaron: [
    {
      text: "Aaron Male is an award-winning Toronto-based bartender, mixologist, and sous chef, with almost twenty years of industry experience. Currently part of the bar team at Miss Thing’s, a popular Polynesian/Tiki restaurant and previously the head bartender at Campagnolo Toronto, one of En Route magazine’s Canada’s Best New Restaurants in 2011, Male brings his signature culinary flair to the crafted cocktail. Prior to Campagnolo, Male was Head Bartender and Mixologist at Toronto’s Brasaii Restaurant and Lounge. He also spent ten years working as a sous chef with La Cantina Ristorante, a boutique Italian restaurant in Hamilton, Ontario."
    },
    {
      text: "Male transitioned to mixology after being inspired at the Canadian Restaurant and Food Association trade show by three experienced bar tenders who taught him about mixology, fresh juices, and infusions. Now widely acknowledged as one of Toronto’s most talented bartenders, Male was formally recognized as the Ontario Grey Goose Pourmaster champion in 2014. A strong cocktail competitor, he regularly places in the top two in both provincial and national mixology competitions."
    },
    {
      text: "Male’s bartending style focuses on maximizing flavor with the fewest number of ingredients, always fresh. Career highlights include the creation of custom cocktails for Global Television’s The Morning Show and coordination of an El Gobernador Pisco competition and tasting class. He has also created custom cocktails for an Oscar-nominated actor."
    },
  ],
  Alex: [
    {text: "Alex Tarkowski is the team's project manager and web developer. You can find him ensuring any libarion related projects are running smoothly with his strong client management experience. More frequently, you will find him behind the computer screen keeping this site and others up to date and looking great"},
  ],
  Mackenzie: [
    {text: "Mackenzie Irons is a hospitality leader managing some of Toronto's top event spaces and cocktail bars. With ten years of experience managing bar programs, training staff, developing cocktail menus, and executing ambassador strategies.  Irons also excels when developing innovative ways of delivering customer service. Whether it is flair bartending, high-end mixology, creative client facing, or hosting events, Irons' experience at all levels of the industry compliments the Libation team."}
  ],
  Oliver: [
    {text:"A true spirit and hospitality enthusiast, Oliver brings over eight years of bar and mixology experience to Libation.  Oliver possesses a formalized spirits education from George Brown College and couples that with years of practically lived industry experience. Oliver has worked in leadership roles at large clubs, promoted brands to bars and restaurants, and led successful creative bar programs. Oliver possesses a personal passion for developing specialty cocktails that create personalized and elevated guest experiences. Oliver's inherent people skills result in invaluable insights about customers and their needs."}
  ],
  pageIntro: "Libation Consulting Ltd, prioritizes education and training from industry leaders around the world.  Whether in a historic sake bar in Tokyo or the fields of Cognac, France, we send our ambassadors and teachers to the farthest reaches of the world to learn about unique flavours and practices, here are key members of the team. "
}
