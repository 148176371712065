import Jordan from './Jordan.png'
import Isabelle from './Isabelle.png'
import Aaron from './Aaron.png'
import Alex from './Alex.png'
import Oliver from './Oliver.png'
import Mackenzie from './Mackenzie.png'
import Default from './Default.jpg'

const TeamImages = {
  Jordan: Jordan,
  Trevor: Default,
  Isabelle: Isabelle,
  Mackenzie: Mackenzie,
  Oliver: Oliver,
  Aaron: Aaron,
  Alex: Alex,
}

export default TeamImages
