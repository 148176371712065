import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHero from '../components/pageHero'
import teamHero from '../images/team-hero.png'
import TeamImages from '../images/team/TeamImages'
import {teamCopy} from '../copy/teamCopy'
import './team.css'

const teamMembers = [
  {name: "Jordan Bushell", title: "President and Chief Consultant"},
  {name: "Trevor Mayoh", title: "Vice-President"},
  {name: "Isabelle Speerin", title: "Content Creator"},
  {name: "Aaron Male", title: "Mixologist & Chef"},
  {name:"Mackenzie Irons", title: "Consultant"},
  {name: "Oliver Skaab", title: "Consultant"},
  {name: "Alex tarkowski", title: "Project/Web Manager"}
]

function setText(key) {
  if (Object.keys(teamCopy).includes(key)) {
    return teamCopy[key].map((text,i) => {
      return <p key={key + i}>{text.text}</p>
    })
  }
}

function setNameAndTitle(key) {
  return teamMembers.filter(person => person.name.includes(key)).map(person => {
    return <div key={person.name}>
              <p className="tm-name">{person.name}</p>
              <p className="tm-title">{person.title}</p>
            </div>
  })
}

const TeamPage = () => (
  <Layout>
    <SEO title="Libation Ltd Team" />
    <PageHero {...{title: "Team", image: teamHero}}/>
    <div className="page-section flex">
      <h4 className="text-block">{teamCopy.pageIntro}</h4>
    </div>
    <div className="team-members flex">
    {Object.keys(TeamImages).map(key => {
      return <div key={key} className="team-member">
              <div className="team-image"
      style={{backgroundImage: `url(${TeamImages[key]})`}}>
      </div>
        {setNameAndTitle(key)}
        {setText(key)}
      </div>
    })}
    </div>
  </Layout>
)

export default TeamPage
